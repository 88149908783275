interface ILayoutBody {
  children: any;
  isPadding?: boolean;
  isHide?: boolean;
  isFixed?: boolean;
}

const LayoutBody = ({ children, isPadding = false, isHide, isFixed }: ILayoutBody) => {
  const paddingBottom = !isFixed ? ' pb-[24px]' : '';
  const padding = isPadding ? 'px-[24px]' + paddingBottom : 'p-[0]';
  const fixed = isFixed ? 'flex flex-col justify-between overflow-auto' : '';

  return (
    <>
      <div className={`relative flex-[2] ${padding} ${fixed}`}>{children}</div>
    </>
  );
};

export default LayoutBody;
